<template>
  <div class="container xl">
    <div class="main-cols">
      <div class="left-menu-col">
        <LeftMenu />
      </div>
      <div class="right-content-col">
        <OrdersList />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu';
import OrdersList from '@/components/orders/OrdersList';

export default {
  name: 'OrdersView',
  components: {
    LeftMenu,
    OrdersList,
  },
};
</script>

