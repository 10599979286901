<template>
  <div class="loading-overlay" v-if="loading">
    <div class="loader"></div>
  </div>
  <div class="orders mb-5" v-if="!loading">
    <div class="open-orders" v-if="$store.state.orders">
      <h5 style="font-size: 36px; font-weight: bold; background: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%) text; -webkit-text-fill-color: transparent; margin: 0px;">My Orders</h5>
      <div class="container">
        <div class="row mb-3">
          <div class="col-md-6 col-sm-6" style="padding-left: 0px;">
            <input type="text" class="form-control mt-2" placeholder="Search Orders" style="border: 1px solid #ECECEC; border-radius: 9px;" v-model="searchQuery" @input="filterOrders">
          </div>
          <div class="col-md-1 col-sm-1">
            <button 
              class="btn btn-primary btn-block mt-2" 
              style="height: 37px; background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%);" >
              <img src="../../assets/search.png" alt="Search">
            </button>
          </div>
        </div>
      </div>
      <div class="orders-list">
        <table class="order-table">
          <thead>
            <tr>
              <th style="border-top-left-radius: 15px;">Order No</th>
              <th>Recipient</th>
              <th>Package Details</th>
              <th>Destination Address</th>
              <th>Carrier Name</th>
              <th>Status</th>
              <th>Total Amount</th>
              <th style="border-top-right-radius: 15px;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="filteredOrders.length === 0">
              <td colspan="8" style="text-align: center;">No orders yet.</td>
            </tr>
            <tr v-for="order in filteredOrders" :key="order.id" v-else>
              <td style="border-bottom-left-radius: 15px;">{{ order.orderId }}</td>
              <td>{{ order.collectionAddress.contactName }}</td>
              <td>{{ order.packageDetails }}</td>
              <td>
                {{ order.collectionAddress.primary }}
                {{ order.collectionAddress.secondary }}
                {{ order.collectionAddress.landmark }},
                {{ order.collectionAddress.cityName }}
              </td>
              <td>
                <img
                  :src="order.selectedCompany.logoImage"
                  alt="Carrier Logo"
                  class="carrier-logo"
                />
              </td>
              <td>{{ order.status }}</td>
              <td>{{ order.totalPrice }} AED</td>
              <td style="border-bottom-right-radius: 15px;">
                <button
                  class="btn btn-primary-next"
                  style="background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); color: #fff; width: 100%; height: 38px; border: none; border-radius: 7px;"
                  @click="showModal(order)"
                >
                  <i class="fa fa-eye"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    </div>

    <div v-if="$store.state.orders && !$store.state.orders.length" class="no-content-message">
      <p>No orders yet.</p>
    </div>

    <div v-if="isModalVisible" class="modal-overlay">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <p style="margin: 0px !important; color: #1985A1; font-weight: bold;">Order No:</p><br/> <span> {{ selectedOrder.orderId }}</span>
            </div>
            <div class="col-md-4">
              <p style="margin: 0px !important; color: #1985A1; font-weight: bold;">Recipient:</p><br/> {{ selectedOrder.collectionAddress.contactName }}
            </div>
            <!-- <div class="col-md-4">
              <p style="margin: 0px !important; color: #1985A1; font-weight: bold;">Package Details:</p><br/> {{ selectedOrder.packageDetails }}
            </div> -->
            <div class="col-md-4">
              <p style="margin: 0px !important; color: #1985A1; font-weight: bold;">Status:</p><br/> {{ selectedOrder.status }}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-4">
              <p style="margin: 0px !important; color: #1985A1; font-weight: bold;">Collection Address:</p> 
              {{ selectedOrder.collectionAddress.primary }},
              {{ selectedOrder.collectionAddress.secondary }},
              {{ selectedOrder.collectionAddress.landmark }},
              {{ selectedOrder.collectionAddress.cityName }}
            </div>
            <div class="col-md-4">
              <p style="margin: 0px !important; color: #1985A1; font-weight: bold;">Collection Date:</p> {{ selectedOrder.collectionDate }}
            </div>
            <div class="col-md-4">
              <p style="margin: 0px !important; color: #1985A1; font-weight: bold;">Carrier Name:</p> 
              <img :src="selectedOrder.selectedCompany.logoImage" alt="Carrier Logo" class="img-fluid mt-1" width="100" height="100" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-4">
              <p style="margin: 0px !important; color: #1985A1; font-weight: bold;">Destination Address:</p> 
              {{ selectedOrder.destinationAddress.primary }},
              {{ selectedOrder.destinationAddress.secondary }},
              {{ selectedOrder.destinationAddress.landmark }},
              {{ selectedOrder.destinationAddress.cityName }}
            </div>
            <div class="col-md-4">
              <p style="margin: 0px !important; color: #1985A1; font-weight: bold;">Total Amount:</p> {{ selectedOrder.totalPrice }} AED
            </div>
            <div class="col-md-4">
              <button style="color: rgb(255, 255, 255); background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%); padding: 20px 40px !important; border-radius: 10px;" type="button" class="btn btn-primary" @click="closeModal">
                Done
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="closeModal">
            Done
          </button>
        </div> -->
      </div>
    </div>

    <!-- Delivered Orders : Temporarily commented -->
    <!-- <div class="delivered-orders">
      <div class="title-wrapper">
        <div class="left"></div>
        <div class="title">Delivered</div>
        <div class="right"></div>
      </div>

      <div class="orders-list">
        <div class="delivered-order">
          <div class="status">
            <div class="title">Delivered</div>
            <p>March 31, 2021 - 8:32am</p>
          </div>
          <div class="address">
            <div class="title">Elizabeth Swann</div>
            <p>New York, United States</p>
          </div>
          <div class="details">
            <router-link :to="{ path: '/order/' + 33 }" class="btn details">Details</router-link>
          </div>
        </div>

        <div class="delivered-order">
          <div class="status">
            <div class="title">Delivered</div>
            <p>March 31, 2021 - 8:32am</p>
          </div>
          <div class="address">
            <div class="title">Elizabeth Swann</div>
            <p>New York, United States</p>
          </div>
          <div class="details">
            <router-link :to="{ path: '/order/' + 33 }" class="btn details">Details</router-link>
          </div>
        </div>

        <div class="delivered-order">
          <div class="status">
            <div class="title">Delivered</div>
            <p>March 31, 2021 - 8:32am</p>
          </div>
          <div class="address">
            <div class="title">Elizabeth Swann</div>
            <p>New York, United States</p>
          </div>
          <div class="details">
            <router-link :to="{ path: '/order/' + 33 }" class="btn details">Details</router-link>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import OpenOrder from '@/components/orders/OpenOrder';

export default {
  name: 'OrdersList',
  // components: { OpenOrder },
  data() {
    return {
      loading: false,
      isModalVisible: false,
      selectedOrder: null,
      searchQuery: '',
    }
  },
  mounted() {
    this.loading = true;
    
    this.$store.dispatch('fetchOrders').then(() => {
      this.loading = false;
    });
  },
  computed: {
    filteredOrders() {
      if (!this.searchQuery) {
        return this.$store.state.orders;
      }
      return this.$store.state.orders.filter(order => 
        order.orderId.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        order.collectionAddress.contactName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  methods: {
    showModal(order) {
      this.selectedOrder = order;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedOrder = null;
    },
    handleModalAction() {
      // Your action logic here
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
/* @import '../../scss/partials/variables.scss'; */

/* Delivered Orders : Temporarily commented */
/* .delivered-orders {
  margin-bottom: 120px;
  .title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;

    .left,
    .right {
      border-top: solid 2px #e3dfeb;
      height: 2px;
      flex: 1;
    }

    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      letter-spacing: 1px;
      color: #d1cade;
      padding: 0px 30px;
      text-transform: uppercase;
    }
  }

  .delivered-order {
    display: flex;
    width: 100%;
    padding: 28px;
    border-radius: 5px;
    background-color: rgba(188, 184, 254, 0.14);
    align-items: center;
    margin-bottom: 25px;

    > div {
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #252423;
      }

      p {
        font-size: 12px;
        color: #252423;
        margin: 5px 0px 0px;
      }
    }

    .status {
      background-image: url($srcPath+'assets/yellow-delivered-icon.svg');
      background-position: left center;
      background-repeat: no-repeat;
      padding-left: 54px;
      flex: 0 0 260/744 * 100%;
      background-size: contain;
    }

    .address {
      flex: 1;
    }

    .details {
      .btn {
        padding: 9px 50px;
        border-radius: 18px;
        box-shadow: 0 2px 20px 0 rgba(147, 146, 173, 0.48);
        background-color: #9392ad;
        display: inline-block;
      }
    }
  }

  @media (max-width: 768px)
  {
    .delivered-order {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (max-width: 640px)
  {
    margin-bottom: 60px;

    .delivered-order {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .status {
        margin-bottom: 30px;
        flex: 1;
      }

      .address {
        margin-bottom: 30px;
        text-align: right;
      }

      .details {
        flex: 0 0 100%;
        text-align: right;
      }
    }
  }


  @media (max-width: 450px)
  {
    .delivered-order { 
      display: block;

      .address {
        text-align: left;
      }

      .details {
        text-align: left;
      }
    }
  }
} */

.orders-container {
  width: 100%;
  padding: 20px;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
}

.order-table th, .order-table td {
  padding: 16px 16px;
  text-align: left;
  font-size: 13px;
  vertical-align: middle;
}

.order-table th {
  background-color: #17748c;
  // background-image: linear-gradient(100deg, rgb(25, 133, 161) 0%, rgb(2, 68, 85) 100%);
  color: #fff;
}

.order-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

img.carrier-logo {
  width: 70px;
  height: 50px;
}

.no-content-message {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 52px 25px;
  border-radius: 10px;
  width: 700px;
  max-width: 90%;
}

.modal-header .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}
</style>
